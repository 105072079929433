import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  SearchV2RequestInterface,
  SearchV2ResponseInterface,
} from '@component-library';
import { environment } from '../../../../environments/environment';
import { NotificationsInterface } from '../../interfaces/notifications/notifications-interface';
import { catchError, map, of, tap } from 'rxjs';
import { GlobalMessageService } from '../global-services/global-message-service/global-message.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  globalMessageService = inject(GlobalMessageService);
  constructor(private http: HttpClient) {}

  searchv2(searchv2: SearchV2RequestInterface) {
    return this.http
      .post<SearchV2ResponseInterface>(
        `${environment.baseUrl}/appNotifications/searchV2`,
        searchv2
      )
      .pipe(
        catchError(() =>
          of({
            CurrentPage: 0,
            CurrentPageSize: 0,
            Items: [],
            Message: '',
            Status: false,
            TotalCount: 0,
          })
        ),
        map((response: SearchV2ResponseInterface) => {
          if (response && response.Status) {
            return {
              CurrentPage: response.CurrentPage || 0,
              CurrentPageSize: response.CurrentPageSize || 0,
              Items: response.Items || [],
              Message: response.Message || '',
              Status: response.Status || false,
              TotalCount: response.TotalCount || 0,
            };
          }
          return {
            CurrentPage: 0,
            CurrentPageSize: 0,
            Items: [],
            Message: '',
            Status: false,
            TotalCount: 0,
          };
        })
      );
  }

  search = (request: any) => {
    return this.http
      .post<{
        Items: NotificationsInterface[];
        TotalCount: number;
        Page: number;
        PageSize: number;
        Status: number;
        Message: any;
      }>(`${environment.baseUrl}/notification/search`, request)
      .pipe(
        catchError(() =>
          of({
            Items: [],
            TotalCount: 0,
            Page: 0,
            PageSize: 0,
            Status: false,
            Message: 'Bir hata oluştu. lütfen tekrar deneyiniz.',
          })
        )
      );
  };

  read = (id: string) => {
    return this.http
      .post<{ Status: boolean; Message: string }>(
        `${environment.baseUrl}/notifications/read`,
        {
          Id: id,
        }
      )
      .pipe(
        catchError(() =>
          of({
            Status: false,
            Message: 'Bildirim okuma işlemi sırasında bir hata oluştu.',
          })
        )
      );
  };

  bulkRead() {
    return this.http
      .get<{ Status: boolean; Message: string }>(
        `${environment.baseUrl}/notifications/bulkRead`
      )
      .pipe(
        catchError(() =>
          of({
            Status: false,
            Message: 'Bildirim okuma işlemi sırasında bir hata oluştu.',
          })
        ),
        tap((f: any) => {
          this.globalMessageService.showStatusMessages({
            status: f && f.Status,
            summary: `Tüm Bildirimleri Oku`,
            infoDetail: `Tüm bildirimler başarıyla okundu.`,
            errorDetail: 'Hata bildirimler okunamadı.',
          });
        })
      );
  }
}
