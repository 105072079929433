import { Component, inject, OnDestroy } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { NotificationsInterface } from '../../../core/interfaces/notifications/notifications-interface';
import { NotificationsService } from '../../../core/services/appNotifications/notifications.service';
import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { GlobalMessageService } from '../../../core/services/global-services/global-message-service/global-message.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'magenty-notification-detail',
    imports: [CommonModule, DialogModule],
    providers: [DatePipe],
    templateUrl: './notification-detail.component.html',
    styleUrl: './notification-detail.component.scss'
})
export class NotificationDetailComponent implements OnDestroy {
  dashboardNotification: NotificationsInterface | undefined;
  notificationsService = inject(NotificationsService);
  globalMessageService = inject(GlobalMessageService);
  ref = inject(DynamicDialogRef);
  dialogService = inject(DialogService);
  instance: DynamicDialogComponent | undefined;
  private destroy$ = new Subject<void>();

  constructor() {
    this.instance = this.dialogService.getInstance(this.ref);
    const instanceData = this.instance.data;

    if (!instanceData) {
      return;
    }

    this.getNotification(instanceData.id);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getNotification = (id: string) => {
    this.notificationsService
      .search({
        Page: 0,
        PageSize: 99,
        Filter: {
          Id: id,
          Type: 99,
          IsRead: 99,
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response && response.Status) {
          this.dashboardNotification = response.Items[0];

          if (!this.dashboardNotification.IsRead) {
            this.readNotification(this.dashboardNotification.Id);
          }
        }
      });
  };

  readNotification = (id: string) => {
    this.notificationsService
      .read(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((f) => {
        if (f && f.Status && this.dashboardNotification) {
          this.dashboardNotification.IsRead = true;
        }
      });
  };
}
