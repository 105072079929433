@if(dashboardNotification) {

    <div class="flex flex-col lg:flex-row items-center gap-6 ">

      <div>

        <img src='https://images.unsplash.com/photo-1643845910538-bf91b570e808?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fG5vdGlmaWNhdGlvbnxlbnwwfHwwfHx8MA%3D%3D' alt="notification" height="180" width="180" style="object-fit:cover">

      </div>

      <div class="flex flex-col w-full" style="min-height:180px">
        <div class="flex flex-col gap-2 md:gap-0 md:flex-row items-center w-full">
          <p class="m-0 p-0 text-xl font-semibold " style="text-wrap:nowrap">
            {{ dashboardNotification.Title }}

          </p>
          <div class="w-full flex justify-between items-center">
              <span
                class=" ml-2 text-sm font-semibold text-white p-1 rounded-2xl"
                [ngClass]="{

                'bg-green-500':dashboardNotification.IsRead,
                'bg-red-500':!dashboardNotification.IsRead

                }">
                {{dashboardNotification.IsRead ? 'Okundu':'Okunmadı'}}

                  </span>

            <p class="m-0 p-0 text-sm font-semibold">{{ dashboardNotification.CreatedOn | date:"dd.MM.yyyy HH:mm:ss"}}</p>
          </div>


        </div>

        <span class="leading-none mt-6" [innerHTML]="dashboardNotification.Description "></span>
      </div>
    </div>

<!--    @if(!dashboardNotification.IsRead){

      <div class="col-span-12 px-0 pt-2 pb-0 -mb-2 border-t border-surface flex justify-end">
        <button pButton label="Okundu olarak işaretle" (click)="readNotification(dashboardNotification.Id)" class="hover:shadow-none focus:shadow-none ml-auto"></button>
      </div>
    }
    -->


}
